

@mixin spinner($color, $border-width: 3px, $diameter: null) {
  @include spinner-border-color($color);

  display: inline-block;

  vertical-align: middle;

  border: {
    width: $border-width;

    style: solid;
  }
  border-radius: 50%;

  composes: spin from '@/assets/stylesheets/utils/_animations.scss';
  

  @if ($diameter) {
    width: $diameter;
    height: $diameter;
  }
}

@mixin spinner-border-color($color) {
  border: {
    top-color: $color;
    right-color: $color;
    bottom-color: transparent;
    left-color: $color;
  }
}

@function retina-path($path) {
  $ext-index: str-index($path, '.');
  @return str-insert($path, '@2x', $ext-index);
}
