@mixin retina-image($img-path, $size: auto, $retina-size: auto) {

  background-image: url($img-path);
  background-size: $size;

  $retina-img-path: retina-path($img-path);
  $retina-size: if($retina-size == auto, $size, $retina-size);

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dppx) {
    background-image: url($retina-img-path);
    background-size: $retina-size;
  }
}
