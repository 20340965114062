@import 'assets/stylesheets/commons';

$modal-overlay-color: rgba($base-black-color, 0.4);
$modal-content-background: $base-white-color;

.modal {

  &Overlay {
    position: fixed;
    z-index: $z-modal;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    overflow: auto;

    padding: gutter();

    background-color: $modal-overlay-color;

    @include media(small mobile) {
      padding: 0;
    }
  }

  &Content {
    position: relative;

    margin: auto;

    outline: none;

    @include media(small mobile) {
      width: 100%;
    }
  }

  > div {
    @include media(small mobile) {
      height: 100%;
    }
  }
}
