@import 'assets/stylesheets/commons';


$modal-content-background: $base-white-color;
$modal-header-border-color: $base-pinkish-grey-color;

.modal {
  width: 740px;
  margin: 0 auto;

  background: $modal-content-background;

  @include media(small mobile) {
    overflow-y: scroll;

    width: 100%;
    min-height: 100vh;
    padding-top: 70px;
  }
}

%content {
  padding: {
    top: 35px;
    right: 85px;
    bottom: 65px;
    left: 85px;
  }

  @include media(small mobile) {
    padding: 20px;
  }
}

.content {
  @extend %content;
}

.contentSmallTop {
  @extend %content;

  padding-top: 5px;
}

.contentBigTop {
  @extend %content;

  padding-top: 65px;
}

.contentSmallBottom {
  @extend %content;

  padding-bottom: 35px;
}

.contentFallback {
  @extend %content;

  padding-top: 65px;

	display: flex;
	justify-content: center;
	align-items: center;
}

.footer {
  @include susy-clearfix;

  padding: 10px;

  border-top: 1px solid $modal-header-border-color;

  &PushRight {
    float: right;
  }
}
