@import 'assets/stylesheets/commons';

$loader-default-border-color: $base-white-color;
$loader-primary-border-color: $primary-color;
$loader-secondary-border-color: $secondary-color;
$loader-ternary-border-color: $ternary-color;

.loader {
  @include spinner ($loader-default-border-color, 3px, 45px);

  &Primary {
    @include spinner-border-color($loader-primary-border-color);
  }

  &Secondary {
    @include spinner-border-color($loader-secondary-border-color);
  }

  &Ternary {
    @include spinner-border-color($loader-ternary-border-color);
  }
}
