@mixin button($bg) {
  background: $bg;

  &:hover {
    background: darken($bg, 8%);
  }

  &:active {
    background: darken($bg, 25%);
  }

  &:focus {
    outline: none;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    background: $bg;
  }
}

// scss-lint:disable VariableForProperty
@mixin button-hollow($color, $hover-text-color) {
  color: $color;
  box-shadow: inset 0 0 0 2px $color;
  background: transparent;
  border: 0;

  &:hover {
    color: $hover-text-color;
    background: $color;
  }

  &:active {
    background: darken($color, 25%);
    box-shadow: inset 0 0 0 2px darken($color, 25%);
  }

  &:focus {
    outline: none;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active {
    color: $color;
    background: transparent;
    box-shadow: inset 0 0 0 2px $color, 25%;
  }
}
// scss-lint:enable

@mixin close-icon($color, $width: 18px, $height: 18px, $line-size: 2px) {
  position: relative;

  width: $width;
  height: $height;

  cursor: pointer;

  &::before,
  &::after {
    position: absolute;

    display: block;

    width: 100%;
    height: $line-size;

    content: '';

    background: $color;
  }

  &::before {
    top: 0;

    transform: translateY(#{$width / 2}) rotate(45deg);
  }

  &::after {
    $offset: $width / 2 - $line-size / 2;

    @if ($offset % 2 != 0) {
      $offset: $offset - 1;
    }

    bottom: 0;

    transform: translateY(-#{$offset}) rotate(-45deg);
  }
}

@mixin post-hover-animation($shadow-color, $shadow-on-pseudo-element: '', $transition-duration: 0.2s, $translate-y: -5px) {
  cursor: pointer;
  transition-duration: $transition-duration;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-in-out;

  transform: translateY(0);

  @if ($shadow-on-pseudo-element == 'before') {
    &::before {
      transition: box-shadow $transition-duration ease-in-out;

      box-shadow: none;
    }
  } @else if ($shadow-on-pseudo-element == 'after'){
    &::after {
      transition: box-shadow $transition-duration ease-in-out;

      box-shadow: none;
    }
  } @else {
    box-shadow: none;
  }

  &:hover {
    transform: translateY($translate-y);

    @if ($shadow-on-pseudo-element == 'before') {
      &::before {
        box-shadow: 0 10px 20px 0 $shadow-color;
      }
    } @else if ($shadow-on-pseudo-element == 'after'){
      &::after {
        box-shadow: 0 10px 20px 0 $shadow-color;
      }
    } @else {
      box-shadow: 0 10px 20px 0 $shadow-color;
    }
  }
}

@mixin link-hover-animation($color, $height: 3px) {
  display: inline-block;

  cursor: pointer;

  &::after {
    display: block;

    width: 0;
    height: $height;

    content: '';
    transition: width 0.2s ease-in-out;

    background: $color;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
}

@mixin brokenships-container($max-width: false) {
  @if ($max-width) {
    @include container($max-width);
  } @else {
    @include container();
  }

  padding: {
    right: $container-padding;
    left: $container-padding;
  }
}

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin accelerate($name) {
  will-change: $name;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

// scss-lint:disable VendorPrefix, SpaceBeforeBrace, PseudoElement
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }

  &:-moz-placeholder {
    @content
  }

  &::-moz-placeholder {
    @content
  }

  &:-ms-input-placeholder {
    @content
  }
}
// scss-lint:enable

@mixin stretch($type: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: $type;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
