@import 'assets/stylesheets/commons';

$bottom-nav-dimmed-background-color: $base-gray-lighter-color;

$full-width: (
  gutters: 0,
  column-width: false
);

@include with-layout($full-width) {
  .bottomNav {
    overflow: hidden;

    @include media(desktop large) {
      .linkContainer {
        transform: skewY(2deg);
        transform-origin: 100% 50%;
      }

      .tiltWrapper {
        overflow: hidden;

        transform: skewY(-2deg);
        transform-origin: 100% 50%;
      }
    }

    &Dimmed {
      background: $bottom-nav-dimmed-background-color;
    }

    .linkContainer {
      @include container();
    }

    &Button {
      min-width: 210px;
      height: 64px;
      padding: 20px 32px;
    }

    &Link {
      position: relative;

      height: 253px;
      padding-top: 20px;

      @include media(tablet desktop large) {
        @include span(6);

        padding-top: 0;

        &:last-child {
          @include span(6 last);
        }
      }

      &Button {
        font-size: 20px;

        position: absolute;

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      }

      &Visit {
        @include retina-image('/images/visit.png', cover);

        background-color: $base-purple-blue-color;
        background-position: center center;
        background-size: 90% 90%;

        button:hover {
          color: $base-purple-blue-color;
        }
      }

      &Collection {
        @include retina-image('/images/collection.png', cover);

        background-color: $base-dark-purple-color;
        background-position: top center;

        button:hover {
          color: $base-dark-purple-color;
        }
      }

      &Stories {
        @include retina-image('/images/stories.png', cover);

        background-color: $base-green-color;
        background-position: bottom center;

        button:hover {
          color: $base-green-color;
        }
      }
    }
  }
}
