@import 'assets/stylesheets/commons';
$cookie-background-color: $primary-color;

// scss-lint:disable ImportantRule SelectorFormat
.cookieBanner {
	background-color: $cookie-background-color !important;
	height: 80px;
	align-items: center !important;
	justify-content: center !important;
	flex-wrap: nowrap !important;

	@include media(small mobile) {
		flex-direction: column;
		justify-content: center !important;
		height: 180px;
	}

	&Content {
		flex: 1 1 auto !important;
		margin: 0 !important;
		height: max-content;

		@include media(small mobile) {
			flex: 0 1 auto !important;
		}

		p {
			color: #F0F0F0;
			line-height: 1.4;
			text-align: center;
			font-weight: 500;
			margin: 0;
		}
	}


	&Button {
		@include media(small mobile) {
			margin-top: 24px !important;
		}

		&Wrapper{
			display: flex;
		}
	}
}
