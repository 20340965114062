@import 'assets/stylesheets/commons';

.languagePicker {
  display: block;
  position: relative;

  &.primary {
    --language-picker-trigger-text-color: white;
  }

  &.secondary {
    --language-picker-trigger-text-color: #9570C2;
  }

  &Trigger {
    font-size: 17px;
    line-height: 21px;
    cursor: pointer;

    position: relative;
    display: block;

    text-decoration: none;
    letter-spacing: 0.5px;

    color: var(--language-picker-trigger-text-color);
    border: 0;
    background: transparent;

    &::after {
      position: absolute;
      bottom: -3px;

      display: block;

      width: 100%;
      height: 3px;

      content: '';

      background: var(--language-picker-trigger-text-color);
    }

    &::before {
      float: right;

      height: 5px;
      margin: {
        top: 8px;
        left: 5px;
      }

      content: '';

      border: {
        top: 7px solid var(--language-picker-trigger-text-color);
        right: 5px solid transparent;
        bottom: 0;
        left: 5px solid transparent;
      }
    }
  }

  &Content {
    position: absolute;
    right: 0;
    min-width: 214px;
    margin-top: 20px;
    padding: 8px 0 8px 16px;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);

    &::after {
      position: absolute;
      top: -7px;
      left: 10px;
      content: "";
      border-top: transparent;
      border-right: 10px solid transparent;
      border-bottom: 7px solid #fff;
      border-left: 10px solid transparent;
      right: 10px;
      left: auto !important;
    }
  }

  &Active > a::before {
    transform: rotate(180deg);
  }
}
