@import 'assets/stylesheets/commons';

$hamburger-primary-color: $base-white-color;
$hamburger-secondary-color: $base-white-color;
$hamburger-dimmed-color: $base-pale-grey;
$hamburger-light-color: $base-white-color;
$mobile-nav-background-color: $base-purple-color;
$mobile-nav-normal-text-color: rgba($base-white-color, 0.6);
$mobile-nav-active-text-color: $base-white-color;
$mobile-nav-separator-color: $base-light-purple-color;
$mobile-nav-shadow-color: rgba(0, 0, 0, 0.25);

.mobileNav {
	padding: 28px 25px;

	&Hamburger {
		position: relative;
		z-index: $z-2;

		width: 32px;
		height: 22px;

		cursor: pointer;
		transition: 0.5s ease-in-out;
		transform: rotate(0deg);

		span {
			position: absolute;
			left: 0;

			display: block;

			width: 100%;
			height: 2px;

			transition: transform 0.25s ease-in-out, top 0.25s ease-in-out, width 0.25s ease-in-out, opecity 0.25s ease-in-out;
			transform: rotate(0deg);

			opacity: 1;

			outline: 1px solid transparent;

			background-clip: content-box;

			box-shadow: 0 1px 2px 0 $mobile-nav-shadow-color;

			will-change: transform;

			backface-visibility: hidden;

			perspective: 1000;

			&:nth-child(1) {
				top: 0;
			}

			&:nth-child(2) {
				top: 10px;
			}

			&:nth-child(3) {
				top: 20px;

				transform: rotate(-10deg);
			}
		}

		&Primary {
			span {
				background: $hamburger-primary-color;
			}
		}

		&Secondary {
			span {
				background: $hamburger-secondary-color;
			}
		}

		&Dimmed {
			span {
				background: $hamburger-dimmed-color;
			}
		}

		&Light {
			span {
				background: $hamburger-light-color;
			}
		}

		&Open {
			span {
				background: $hamburger-light-color;
			}

			span:nth-child(1) {
				top: 10px;

				transform: rotate(45deg);
			}

			span:nth-child(2) {
				width: 0;

				opacity: 0;
			}

			span:nth-child(3) {
				top: 10px;

				transform: rotate(-45deg);
			}
		}
	}

	&Content {
		position: absolute;
		z-index: $z-1;
		top: 0;
		right: 0;
		left: 0;

		padding: 25px;

		transition: transform 0.25s ease-in-out;
		transform: translateY(-100%);

		background: $mobile-nav-background-color;

		will-change: transform;

		&Open {
			transform: translateY(0);
		}
	}


	&Header {
		padding-bottom: 25px;
	}

	&List {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		&Item {
			font-size: 17px;
			font-weight: 600;
			line-height: 48px;

			display: block;

			width: 100%;
			height: 48px;

			cursor: pointer;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			letter-spacing: 0.5px;
			border: none;
			background-color: transparent;

			color: $mobile-nav-normal-text-color;

			&Active {
				color: $mobile-nav-active-text-color;
			}

			&Disabled {
				opacity: 0.7;
			}
		}
	}

	&Separator {
		width: 100%;
		height: 2px;
		margin: 10px 0;

		background: $mobile-nav-separator-color;
	}

	&Accessories {
		margin-top: 15px;

		text-align: center;

		&Link {
			display: inline-block;

			margin-bottom: 10px;
		}
	}
}
