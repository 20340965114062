@import 'assets/stylesheets/commons';

$footer-background-color: $base-white-five;
$footer-list-title-color: $base-brownish-grey;
$footer-list-link-color: $base-warm-lighter-gray-color;
$footer-list-link-hover-color: $base-brownish-grey;
$footer-logo-color: $base-pale-grey;
$footer-trademark-text-color: $base-brownish-grey;

.footer {
  padding: 50px 0 4px;

  background-color: $footer-background-color;

  &Container {
    @include brokenships-container($container-max-width);
  }

  &Logo {
    @include span(3 of 12);
    @include media(small mobile tablet) {
      @include span(12 of 12);

      margin-bottom: 30px;
    }

    &Image {
      fill: $footer-logo-color;
    }
  }

  &Links {
    @include span(9 of 12 last);
    @include media(small mobile tablet) {
      @include span(12 of 12);
    }
  }

  &List {
    @include span(1 of 5);
    @include media(desktop large) {
      &:last-child {
        @include last;
      }
    }

    @include media(tablet) {
      @include gallery(3 of 9);
    }

    @include media(small mobile) {
      @include gallery(6 of 12);
    }

    @include media(small mobile tablet) {
      margin-bottom: 40px;
    }

    &Title {
      font-size: 15px;
      font-weight: 600;
      line-height: 18px;

      margin-bottom: 12px;

      letter-spacing: 0.5px;
			text-transform: uppercase;

      color: $footer-list-title-color;
    }

    &Content {
      margin: 0;
      padding: 0;

      list-style: none;
    }

    &Link {
      font-size: 17px;
      line-height: 27px;

      cursor: pointer;
      text-decoration: none;

      color: $footer-list-link-color;

      &:hover {
        color: $footer-list-link-hover-color;
      }
    }
  }

  &Trademark {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.3;

    width: 100%;
    margin: 32px 0 8px;

    text-align: center;

    color: $footer-trademark-text-color;
  }
}
