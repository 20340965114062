@import 'assets/stylesheets/commons';

$navigation-logo-primary-color: $primary-color;
$navigation-logo-secondary-color: $secondary-color;
$navigation-logo-dimmed-color: $base-pale-grey;
$navigation-logo-light-color: $base-white-color;

.navigation {
  position: absolute;
  z-index: $z-5;
  top: 0;

  width: 100%;

  user-select: none;

  &Container {
    @include brokenships-container($container-max-width);

    height: 104px;
  }

  &Logo {
    display: inline-block;

    margin-top: -15px;

    &Primary {
      fill: $navigation-logo-primary-color;
    }

    &Secondary {
      fill: $navigation-logo-secondary-color;
    }

    &Dimmed {
      fill: $navigation-logo-dimmed-color;
    }

    &Light {
      fill: $navigation-logo-light-color;
    }
  }

  &Desktop {
    display: inline;
    float: right;

    @include media(small mobile tablet) {
      display: none;
    }
  }

  &Mobile {
    display: inline;
    float: right;

    margin-right: -25px;

    @include media(desktop large) {
      display: none;
    }
  }
}
