@import 'assets/stylesheets/commons';

$global-nav-primary-text-color: $primary-color;
$global-nav-primary-active-text-color: $base-white-color;

$global-nav-secondary-text-color: $base-white-color;
$global-nav-secondary-active-text-color: $base-white-color;

$global-nav-dimmed-text-color: $base-pale-grey;
$global-nav-dimmed-active-text-color: $base-white-color;

$global-nav-light-text-color: $base-white-color;
$global-nav-light-active-text-color: $primary-color;

$global-nav-tooltip-background-color: $base-white-color;
$global-nav-tooltip-color: $base-greyish-brown;

$global-nav-dropdown-background-color: $base-white-color;
$global-nav-dropown-box-shadow-color: rgba(0, 0, 0, 0.16);

$global-nav-button-border-color: $base-white-color;


.globalNav {
  display: inline-block;

  margin: 0;
  padding: 0;

  list-style: none;

  &Disabled {
    cursor: pointer;

    opacity: 0.5;
  }

  &Item {
    display: inline-block;

    padding: 0 10px;
  }

  &Link {
    font-size: 17px;
    font-weight: 600;

    display: inline-block;

    padding: 40px 15px 20px 15px;

    cursor: pointer;

    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    &Primary {
      @include link-hover-animation($global-nav-primary-text-color);
      color: $global-nav-primary-text-color;
    }

    &Secondary {
      @include link-hover-animation($global-nav-secondary-text-color);
      color: $global-nav-secondary-text-color;
    }

    &Dimmed {
      @include link-hover-animation($global-nav-dimmed-text-color);
      color: $global-nav-dimmed-text-color;
    }

    &Light {
      @include link-hover-animation($global-nav-light-text-color);
      color: $global-nav-light-text-color;
    }
  }

  &LinkActive {
    background-repeat: no-repeat;
    background-size: 100% 100%;

    &::after {
      display: none;
    }

    &Primary {
      color: $global-nav-primary-active-text-color;
      background-image: url('/assets/icons/nav-item-selected-primary.svg');
    }

    &PrimaryLight {
      color: $global-nav-primary-active-text-color;
      background-image: url('/assets/icons/nav-item-selected-primary-light.svg');
    }

    &Secondary {
      color: $global-nav-secondary-active-text-color;
      background-image: url('/assets/icons/nav-item-selected-secondary.svg');
    }

    &Dimmed {
      color: $global-nav-dimmed-active-text-color;
      background-image: url('/assets/icons/nav-item-selected-dimmed.svg');
    }

    &Light {
      color: $global-nav-light-active-text-color;
      background-image: url('/assets/icons/nav-item-selected-light.svg');
    }
  }

  &Dropdown {
    position: relative;


    &Trigger {
      text-transform: uppercase;
    }

    &Toggle {
      display: inline-block;

      margin-left: 8px;

      vertical-align: middle;

      border-top: 6px solid $global-nav-light-text-color;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;

      &Primary {
        border-top: 6px solid $global-nav-primary-text-color;
      }

      &Secondary {
        border-top: 6px solid $global-nav-secondary-text-color;
      }

      &Dimmed {
        border-top: 6px solid $global-nav-dimmed-text-color;
      }

      &Light {
        border-top: 6px solid $global-nav-light-text-color;
      }
    }

    &Items {
      position: absolute;

      top: 100%;
      left: -20%;

      display: none;

      width: 180px;
      padding: 4px 12px;

      border-radius: 3px;

      background-color: $global-nav-dropdown-background-color;

      box-shadow: 0 2px 4px 0 $global-nav-dropown-box-shadow-color;

      a {
        text-transform: capitalize;
      }

      &::before {
        position: absolute;

        top: -9px;
        right: 10%;

        width: 0;
        height: 0;

        content: '';

        border-right: 10px solid transparent;
        border-bottom: 10px solid $global-nav-dropdown-background-color;
        border-left: 10px solid transparent;
      }
    }

    &:hover &Items {
      display: block;
    }
  }
}

.userNav {
  display: inline;

  &Item {
    display: inline-block;

    padding: 0 10px;
  }
}

// TODO: move this into a separate component
.languageNavLink {
  color: $global-nav-light-text-color;
  border: 0;
  background: transparent;

  &::after {
    display: block;

    height: 3px;

    content: '';

    background: $global-nav-light-text-color;
  }

  &::before {
    float: right;

    height: 5px;

    margin: {
      top: 5px;
      left: 5px;
    }

    content: '';

    border: {
      top: 5px solid $global-nav-light-text-color;
      right: 5px solid transparent;
      bottom: 0;
      left: 5px solid transparent;
    }
  }
}

.tooltip {
  font-size: 17px;
  font-weight: 400;

  border-radius: 2px;

  &:global(.show) {
    opacity: 1;
  }

  &:global(.type-light) {
    color: $global-nav-tooltip-color;
    background-color: $global-nav-tooltip-background-color;
  }
}
