@import '@/assets/stylesheets/commons';

$button-default-padding: 10px;
$button-normal-line-height: 20px;
$button-normal-text-color: $primary-color;
$button-normal-background-color: $base-white-color;

$button-border-radius: 100px;

$button-disabled-background-color: darken($button-normal-background-color, 10%);
$button-disabled-text-color: lighten($button-normal-text-color, 20%);
$button-disabled-border-color: darken($button-normal-background-color, 15%);

$button-types: (
  'Primary' ($primary-color, $secondary-text-color),
  'Secondary' ($secondary-color, $secondary-text-color),
  'Ternary' ($ternary-color, $secondary-text-color),
  'Dimmed' ($base-pinkish-grey-color, $secondary-text-color),
  'Facebook' ($facebook-color, $secondary-text-color),
  'Twitter' ($twitter-color, $secondary-text-color)
);

%button {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;

  display: inline-block;

  padding: 13px 24px 15px 24px;

  cursor: pointer;
  user-select: none;

  border-radius: 0;

  & + & {
    margin-left: 5px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $button-disabled-background-color;
    color: $button-disabled-text-color;
    border-color: $button-disabled-border-color;
    opacity: 0.65;
  }
}

.button {
  @extend %button;
  @include button($button-normal-background-color);
  @include font-smoothing;

  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  text-decoration: none;

  color: $button-normal-text-color;

  border: 0;

  &:disabled {
    background-color: $button-disabled-background-color;
    color: $button-disabled-text-color;
  }

  @each $button-type in $button-types {
    $name:  nth($button-type, 1);
    $color-palette: nth($button-type, 2);
    $background-color: nth($color-palette, 1);
    $text-color: nth($color-palette, 2);

    &#{$name} {
      @include button($background-color);
      color: $text-color;

      &:disabled {
        background-color: lighten($background-color, 15%);
        color: lighten($text-color, 20%);
      }

      .spinner {
        @include spinner-border-color($text-color);
      }

      &.dropdown::before {
        border-top: 7px solid $text-color;
      }
    }
  }
}

.buttonHollow {
  @extend %button;
  @include button-hollow($button-normal-background-color, $button-normal-text-color);

  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  text-decoration: none;

  &:disabled {
    background-color: transparent;
    color: $button-disabled-text-color;
    box-shadow: none;
  }

  .spinner {
    @include spinner-border-color($button-normal-background-color);
  }

  &.dropdown {
    &::before {
      border-top: 7px solid $button-normal-background-color;
    }

    &:hover::before {
      border-top: 7px solid $button-normal-text-color;
    }
  }

  @each $button-type in $button-types {
    $name:  nth($button-type, 1);
    $color-palette: nth($button-type, 2);
    $button-background-color: nth($color-palette, 1);
    $button-text-color: nth($color-palette, 2);

    &#{$name} {
      @include button-hollow($button-background-color, $button-text-color);

      &:disabled {
        background-color: transparent;
        color: lighten($button-text-color, 20%);
        box-shadow: none;
      }

      .spinner {
        @include spinner-border-color($button-background-color);
      }

      &.dropdown {
        &::before {
          border-top: 7px solid $button-background-color;
        }

        &:hover::before {
          border-top: 7px solid $button-text-color;
        }
      }
    }
  }
}

.pill {
  border-radius: $button-border-radius;
}

.spinner {
  @include spinner ($button-normal-text-color, 3px, 18px);
}

.big {
  font-size: 20px;
  line-height: 24px;

  padding: 20px 47px;

  .spinner {
    width: 23px;
    height: 23px;
  }
}

.small {
  font-size: 14px;
  line-height: 16px;

  padding: 5px 12px;

  .spinner {
    width: 16px;
    height: 16px;
  }
}

.block {
  display: block;

  width: 100%;
  margin-left: 0;

  & + & {
    margin: {
      top: 5px;
      left: 0;
    }
  }
}

.dropdown {
  &::before {
    float: right;

    height: 5px;
    margin: {
      top: 8px;
      left: 5px;
    }

    content: '';

    border: {
      top: 7px solid $button-normal-text-color;
      right: 5px solid transparent;
      bottom: 0;
      left: 5px solid transparent;
    }
  }
}
